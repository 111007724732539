<template>
  <div class="d-flex flex-column" :key="category">
    <jumbotron :src="categoryData.mainImage" :key="category">
      <v-container fluid class="fill-height white--text">
        <v-row class="fill-height" no-gutters align="end">
          <v-col>
            <h1
              :class="{
                'display-2': isOnPC,
                'display-1': !isOnPC,
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
              v-text="categoryData.title"
            />
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container class="fill-height">
      <v-row dense>
        <v-col>
          <p
            style="text-align: justify"
            v-if="categoryData.intro"
            v-html="categoryData.intro"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-container class="fill-height" >
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="1"
                v-for="(destination, n) in destinations"
                :key="`${n}${JSON.stringify(destination)}`"
              >
                <destination-card :destination="destination" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row justify="center" v-intersect="populateList">
        <v-progress-circular v-show="loading" indeterminate />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import jumbotron from '../components/Jumbotron'
import firebase from '../plugins/firebase'
import DestinationCardVue from '../components/DestinationCard.vue'

export default {
  props: {
    category: {
      type: String,
      required: true
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({
      loading: false,
      categoryData: {},
      destinations: [],
      lastDestinationDocRef: null,
      finalHit: false
    })
  },

  components: {
    jumbotron,
    'destination-card': DestinationCardVue
  },

  async created () {
    this.$data.loading = true
    const pageData = await Promise.allSettled([
      this.getCategoryDetails()
    ])

    this.$data.categoryData = pageData[0].value
    if (this.$data.categoryData === null) return this.$router.go('/NotFound')

    this.$data.loading = false
  },

  methods: {
    getCategoryDetails () {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.doc(`/destinationCategories/${this.category}`).get().then(doc => {
          if (!doc.exists) resolve(null)
          resolve(doc.data())
        }).catch(e => { reject(e) })
      })
    },

    getDestinations (page, limit = 6) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        var query = db.collection('/destinations').where('category', '==', this.$data.categoryData.query).orderBy('rating', 'desc').orderBy('name').limit(limit)

        if (this.$data.lastDestinationDocRef) query = query.startAfter(this.$data.lastDestinationDocRef)

        query.get().then(querySnapshot => {
          var o = {}

          o.results = querySnapshot.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
          o.lastDestinationDocRef = querySnapshot.docs[querySnapshot.docs.length - 1]
          resolve(o)
        }).catch(e => { reject(e) })
      })
    },

    async populateList () {
      // Check if already fetched the last in the list before fetching
      if (!this.$data.finalHit && !this.$data.loading) {
        this.$data.loading = true

        const fetchedDestinations = await this.getDestinations()

        let existingDestinations = this.$data.destinations
        existingDestinations = existingDestinations.concat(fetchedDestinations.results)
        this.$data.destinations = existingDestinations

        this.$data.lastDestinationDocRef = fetchedDestinations.lastDestinationDocRef
        this.$data.loading = false

        // If results are empty set finalHit to true
        if (!fetchedDestinations.results.length) this.$data.finalHit = true
      }
    }
  },

  watch: {
    categoryData: function (v) {
      document.title = `${v.title} in North Sulawesi - AmborJo`
    }
  }
}
</script>
